<section>
  <div class="container">
    <!--login-->
    <div class="login">
      <div class="row">
        <div class="border-right-gray col-sm-12 col-md-6">
          <h1 class="text-center font-title">Log in to your account</h1>
          <div class="form-container">
            <ea-login-form></ea-login-form>
          </div>


        </div>
        <div class="create-account col-sm-12 col-md-6">
          <h2 class="text-center">New to EnableAll?</h2>
          <a
            [routerLink]="'/register'"
            class="ea-button ea-button-secondary col-sm-12 col-md-12 col-lg-12 col-xl-12 p-2 focus-ring"
            ><span class="material-icons">person</span>
            Create an account
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
