<section>
    <div class="container">

        <div class="row">
            <div class="col-lg-8">
                <div class="container">
                    <ea-order-steps class="mb-4" *ngIf="isLoaded" [step]="step" [isNoAddress]="!selectedAddress"></ea-order-steps>
                </div>
                <div class="w-100" *ngIf="isCardLaded">
                <ea-checkout-addresses (changes)="onAddressChanged($event)" (goToDeleverystep)="goToDeleverystep()"
                    (useThisAddressAsBilling)="useAsBillingAddress()"></ea-checkout-addresses>

                <ea-select-delivery-options class="discktop" *ngIf="selectedAddress" [address]="selectedAddress"
                    [itemsByVendor]="itemsByVendor" [cart]="cart$ | async">
                </ea-select-delivery-options>

                <ea-select-delivery-options *ngIf="selectedAddress && isDelevryOption" [address]="selectedAddress"
                    [itemsByVendor]="itemsByVendor" [cart]="cart$ | async">
                </ea-select-delivery-options>

                <div *ngIf="selectedAddress && isDelevryOption">
                    <div class="address-actions-mobile mt-4 w-100">

                        <div class="row p-3">
                            <!-- <button (click)="toggleDisable(true)" [routerLink]="['/payment']"
                                [disabled]="isCartInvalidToCheckout$ | async" title="Continue"
                                class="ea-button ea-button-primary  w-100 p-1 " aria-label="Continue to payment">
                                Confirm delivery options
                            </button> -->

                            <div class="row" *ngIf="this.useDelievrtyAddressAsBilling">
                                <button (click)="toggleDisable(true)" [routerLink]="['/payment']"
                                    [disabled]="isCartInvalidToCheckout$ | async" title="Continue"
                                    class="ea-button ea-button-primary  w-100 p-1 "
                                    aria-label="Continue to payment">
                                    Confirm delivery options
                                </button>
                            </div>

                            <div *ngIf="!this.useDelievrtyAddressAsBilling">
                                <button (click)="toggleDisable(true)" [routerLink]="['/payment']"
                                    [queryParams]="{billing: selectedAddress.addressId}" [disabled]="isCartInvalidToCheckout$ | async"
                                    title="Continue" class="ea-button ea-button-primary w-100 p-1"
                                    aria-label="Continue to payment">
                                    Confirm delivery options
                                </button>

                            </div>
                            <div class="step-container mt-3">
                                <div class="step-icon">
                                    <span class="material-icons">lock</span>
                                </div>
                                <div class="step-text">
                                    Step 3 - Secure payment
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="col-lg-4 justify-content-end d-none d-md-block">
                <div class="subtotal-content col-12 w-100" [ngClass]="currentFontSize">
                    <div
                    class="total-card"
                  >
                    <div class="row ps-2 pe-2 cart-totals">                        
                        <ng-container *ngIf="cart$ | async as cart">
                            <div class="row ps-2 pe-2 cart-totals">
                                <ea-cart-totals></ea-cart-totals>
                              </div>
                            <div class="row" *ngIf="this.useDelievrtyAddressAsBilling">
                                <button (click)="toggleDisable(true)" [routerLink]="['/payment']"
                                    [disabled]="isCartInvalidToCheckout$ | async" title="Continue"
                                    class="ea-button ea-button-primary  w-100 p-1 "
                                    aria-label="Continue to payment">
                                    <span class="material-icons">lock</span> Go to secure payment
                                </button>
                            </div>

                            <div *ngIf="!this.useDelievrtyAddressAsBilling && selectedAddress">
                                <button (click)="toggleDisable(true)" [routerLink]="['/payment']"
                                    [queryParams]="{billing: selectedAddress.addressId}" [disabled]="isCartInvalidToCheckout$ | async"
                                    title="Continue" class="ea-button ea-button-primary w-100 p-1"
                                    aria-label="Continue to payment">
                                   <span class="material-icons">lock</span>  <span>Go to secure payment</span> 
                                </button>

                            </div>
                        </ng-container>
                    </div>
                    </div>
                    <div style="margin-top: 217px;"
                    >    <ea-info-card></ea-info-card></div>
                
                </div>

            </div>

            <div class="d-block d-md-none" [ngClass]="isDelevryOption ?'subtotal-content-mobile-delivery col-lg-12' : 'subtotal-content-mobile col-lg-12'">
                <ng-container *ngIf="cart$ | async as cart">
                    <ng-container *ngIf="cart.totalItems > 0">
                        <div>
                            <div class="justify-content-between m-1 row">
                                <div class="col">
                                    <span>Bag( {{ cart.totalItems }} items)</span>
                                </div>
                                <div class="col text-right " style="font-size: 25px;">
                                    {{ cart.subTotal - estimatedShippingAmount  - cart.totalDiscount  | currency : cart.currency }}

                                </div>
                            </div>
                            <div *ngIf="isDelevryOption">
                                <div class="justify-content-between m-1 row">
                                    <div class="col">
                                        <span>Delivery</span>
                                    </div>
                                    <div class="col text-right " style="font-size: 20px;">
                                        {{ estimatedShippingAmount | currency : cart.currency }}

                                    </div>
                                </div>
                                <hr>
                                <div class="justify-content-between m-1 row">
                                    <div class="col">
                                        <span>Total</span>
                                    </div>
                                    <div class="col text-right total-price">
                                        {{ cart.subTotal -  cart.totalDiscount  | currency : cart.currency }}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</section>
