import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Patterns } from 'src/app/utils/patterns';
import { AuthFacade, CookieService } from '@ea/auth';
import { BaseComponent } from '@ea/components';

@Component({
  selector: 'ea-login-form',
  standalone: true,
  imports: [CommonModule, RouterLink, ReactiveFormsModule, FormsModule],
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent extends BaseComponent {
  // Password Visibility
  passwordVisible: boolean = false;
  isLoading$ = this.auth.isLoading$;
  redirectRoute: string = '';
  loginForm: FormGroup;
  @Input() isCartAuth: boolean = false;
  constructor(
    private readonly builder: FormBuilder,
    private readonly cookies: CookieService,
    private readonly auth: AuthFacade,
    private route: ActivatedRoute,
  ) {
    super();
    this.route.queryParams.subscribe((queryParams) => {
      this.redirectRoute = queryParams['redirectRoute'];
    });

    this.loginForm = this.builder.group({
      uuid: [this.cookies.get('_snrs_uuid')],
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [Validators.required, Validators.pattern(Patterns.password)],
      ],
    });
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  login(): void {
    const payload = this.loginForm.value;
    this.auth.login(payload, this.redirectRoute);
  }
}
