
<div class="info-card focus-outline mt-3" *ngIf="displayEALLDev">
  <img src="assets/images/cardsIcons/eall-info-icon.svg">
  <div>
    <p class="font-title">About EnableAll</p>
    <p>Shopping is secure with EnableAll</p>
  </div>

</div>

<div class="info-card focus-outline mt-3">
  <img src="assets/images/icons/returns.svg" alt="return icon">
  <div>
    <p class="font-title">30 day returns</p>
    <p>Return your item up to 30 days after receiving it</p>
  </div>

</div>
<div class="info-card focus-outline mt-3">
  <img src="assets/images/icons/lock.svg" alt="lock icon">
  <div>
    <p class="font-title">100% secure</p>
    <p>Shopping is secure with EnableAll</p>
  </div>

</div>


