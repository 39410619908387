import { CommonModule } from '@angular/common';
import { Component, Injectable, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Patterns } from 'src/app/utils/patterns';
import { AuthFacade, CookieService } from '@ea/auth';
import { BaseComponent } from '@ea/components';
import {
  CountryISO,
  NgxIntlTelInputModule,
  PhoneNumberFormat,
  SearchCountryField,
} from '@justin-s/ngx-intl-tel-input';
import { ToastService } from '@ea/services';

@Component({
  selector: 'ea-create-account-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    ReactiveFormsModule,
    FormsModule,
    NgxIntlTelInputModule,
  ],
  templateUrl: './create-account-form.component.html',
  styleUrls: ['./create-account-form.component.scss'],
})
export class CreateAccountFormComponent extends BaseComponent {
  @Input() fromCartPage?: Boolean = false;
  passwordVisible: boolean = false;
  registerForm: FormGroup;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  defaultCharity : any;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  separateDialCode = false;

  constructor(
    private builder: FormBuilder,
    private cookies: CookieService,
    private auth: AuthFacade,
    private toasterService: ToastService,
    private route: ActivatedRoute,

  ) {

    super();
    this.route.queryParams.subscribe(params => {
      this.defaultCharity = params['DefaultCharity'] || null;
    });
    this.registerForm = this.builder.group({
      uuid: [this.cookies.get('_snrs_uuid')],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: this.builder.control('', Validators.required),
      password: [
        '',
        [Validators.required, Validators.pattern(Patterns.password)],
      ],
    });
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

  showValidationMessage(message: string) {
    this.toasterService.show(message,'error');
  }

  register() {
    if (this.isFormValid()) {
      this.toggleDisable(true);
      const payload = this.registerForm.value;
      payload.defaultCharity = this.defaultCharity;
      this.auth.register(payload);
      this.toggleDisable(false);
    }
  }

  public isFormValid() {
    if (this.registerForm.status == 'INVALID') {
      if (this.registerForm.controls['phone'].status == 'INVALID') {
        if (this.registerForm.controls['phone'].value.internationalNumber) {
          return true;
        } else {
          this.showValidationMessage('Please enter your Phone number');
        }
      }
      if (this.registerForm.controls['firstName'].status == 'INVALID') {
        this.showValidationMessage('Please enter your First name');
      } else if (this.registerForm.controls['lastName'].status == 'INVALID') {
        this.showValidationMessage('Please enter your Last name');
      } else if (this.registerForm.controls['email'].status == 'INVALID') {
        this.showValidationMessage('Please enter your Email');
      } else if (this.registerForm.controls['password'].status == 'INVALID') {
        this.showValidationMessage(
          "Password must be minimum of 8 characters and contain at least 1 uppercase letter, 1 number and 1special character @$!%*?&#-_.'",
        );
      }
    }

    return this.registerForm.status == 'VALID';
  }
}
