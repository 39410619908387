
<section class="pb-5 pt-4">
  <div class="container">
    <div *ngFor="let order of orders" class="">
      <div class="header">
        <div class="text-theme-on-surface">
          <h1>Order: # <strong>{{ order.orderNumber }}</strong></h1>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-12">
        <div *ngFor="let order of orders">
          <div
            class=" order-card" 
          >
          <div class="disctop">
            <div class="d-flex justify-content-between ">
              <div class="address-ship">
                <h2><strong>Delivery address</strong></h2>
                  <span class="addressItem"> {{ order.shipInfo[0].shipToAddress.address1 }}</span>  <br />
                  <span class="addressItem">{{ order.shipInfo[0].shipToAddress.city }}, {{ order.shipInfo[0].shipToAddress.state }}</span> <br />
                  <span class="addressItem">{{ order.shipInfo[0].shipToAddress.postalCode }}</span>  <br />
                  <span class="addressItem">{{ order.shipInfo[0].shipToAddress.country }}</span>
                  
                </div>
                <div class="address-ship">
                  <h2 > <strong>Order summary</strong> </h2>
                  <div class="d-flex justify-content-between mt-2">
                      <span> Subtotal </span>
                      <span>  {{ order.orderSubTotal | currency : order.currency
                      }}</span>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <span> Delivery </span>
                      <span> {{ order.feeTotal | currency : order.currency
                      }}</span>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <span> Total </span>
                      <span>  {{
                        order.orderSubTotal + order.feeTotal
                          | currency : order.currency
                      }}</span>
                    </div>
                    <div class="d-flex justify-content-between mt-2">
                      <span> VAT </span>
                      <span>                    {{ order.vat     | currency : order.currency}}
                      </span>
                    </div>
            </div>
          </div>
          </div>

          <div class="mobile">
            <div class="address-ship row">
              <h2><strong>Delivery address</strong></h2>
                <span class="addressItem"> {{ order.shipInfo[0].shipToAddress.address1 }}</span>  <br />
                <span class="addressItem">{{ order.shipInfo[0].shipToAddress.city }}, {{ order.shipInfo[0].shipToAddress.state }}</span> <br />
                <span class="addressItem">{{ order.shipInfo[0].shipToAddress.postalCode }}</span>  <br />
                <span class="addressItem">{{ order.shipInfo[0].shipToAddress.country }}</span>
                
              </div>
              <div class="address-ship row">
                <h2 > <strong>Order summary</strong> </h2>
                <div class="d-flex justify-content-between mt-2">
                    <span> Subtotal </span>
                    <span>  {{ order.orderSubTotal | currency : order.currency
                    }}</span>
                  </div>
                  <div class="d-flex justify-content-between mt-2">
                    <span> Delivery </span>
                    <span> {{ order.feeTotal | currency : order.currency
                    }}</span>
                  </div>
                  <div class="d-flex justify-content-between mt-2">
                    <span> Total </span>
                    <span>  {{
                      order.orderSubTotal + order.feeTotal
                        | currency : order.currency
                    }}</span>
                  </div>
                  <div class="d-flex justify-content-between mt-2">
                    <span> VAT </span>
                    <span>                    {{ order.vat     | currency : order.currency}}
                    </span>
                  </div>
                </div>
          </div>
            <div *ngFor="let item of order.items">
              <hr class="mt-1">
              <div class="row disctop">
                <div class="col-md-2 col-lg-2 ">
                  <div class="pt-3"></div>
                  <img
                    [src]="item.product_image | getImageFilter"
                    alt="Product Image"
                  />
                </div>
                <div class="col-md-4 col-lg-4 pt-4">
                  <h2>{{ item.title }}</h2>
                  <br />
                  <p class="text-theme-on-surface">
                    Quantity: {{ item.orderedQuantity }}
                  </p>
                </div>
                <div class="col-md-3 col-lg-3 pt-2 mt-4">
                  <div class=" mt-5">
                    <p>  
                      Total: {{ item.originalItemTotal | currency : "GBP" }}
                    </p>
                  </div>
                </div>
                <div class="col-md-3 col-lg-3 pt-2 text-right mt-4">
                  <div class=" mt-5">
                    <p>  
                      Status: {{ item.lineOrderStatus}}
                    </p>
                    <a
                    *ngIf="order.statusCode === orderStatus.Shipped"
                    [routerLink]="['/account/orders/order-return/', order.orderNumber,item.itemId]"
                    class="remove-btn mt-1"
                  >
                <u>  Return</u>
                  </a>
               
                  </div>
                </div>
              </div>

              <div class="row mobile">
                <div class="row">
                <h2 class="mt-2">{{ item.title }}</h2>
                <div class="col-4 ">
                  <div class="pt-3"></div>
                  <img
                    [src]="item.product_image | getImageFilter"
                    alt="Product Image"
                  />
                </div>
                <div class="col-7 ml-2 mt-1">
                  <p class="text-theme-on-surface mt-1" >
                    Quantity: {{ item.orderedQuantity }}
                  </p>
                  <p class="text-theme-on-surface mt-1" >  
                    Total: {{ item.originalItemTotal | currency : "GBP" }}
                  </p>
                  <p class="text-theme-on-surface mt-1" >  
                    Status: {{ item.lineOrderStatus.toLowerCase()}}
                  </p>
                </div>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
