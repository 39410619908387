<div class="add-to-basket" *ngIf="product">
  <form [formGroup]="form" *ngIf="form" (ngSubmit)="addToBasket()">
    <div class="row mt-2 d-flex justify-content-center align-items-center">
      <div class="col-lg-4 discktop">
        <label class="form-label" for="quantity"> <strong>Quantity</strong> </label>
        <div class="quantity-input-wrapper">
          <button type="button" class="quantity-button" (click)="decreaseQuantity()">-</button>
          <input
            class="quantity-input text-center"
            formControlName="quantity"
            type="text"
            id="quantity"
            value="1"
            min="1"
            (change)="changeQuantity()"
            (keypress)="onlyAllowNumbers($event)"
            alt="quantity input"
          />
          <button type="button" class="quantity-button" (click)="increaseQuantity()">+</button>
        </div>
      </div>
      <div class="col-4 mobile">
        <label class="form-label" for="quantity"><strong>Quantity</strong>  </label>
        <div>
          <select
            class="ea-input w-100 focus-ring"
            formControlName="quantity"
            (change)="changeQuantity()"          >
            <option
              *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              [value]="i"
            >
              {{ i }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-8 ">
        <button
          type="submit"
          *ngIf="(productsInTheCartCount$ | async) ?? 0 == 0; else updateButton"
          [disabled]="!(this.productService.choose$ |async) || (isButtonsDisabled$ | async)"
          class="submit ea-button ea-button-primary focus-ring height mt-4"
        >
        <img class="bag-icon" alt="bag icon" src="assets/images/icons/bag-white.svg"
        />
        Add to bag
        </button>

        <ng-template #updateButton>
          <button
            type="submit"
            class="submit ea-button ea-button-primary focus-ring"
          >
            UPDATE BASKET
          </button>
        </ng-template>
      </div>
    </div>
  </form>
</div>
