import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import environment from 'src/environments/environment';
import { Price } from 'src/app/models/productDetails.model';
import { BnplInfoComponent } from '../bnpl-info/bnpl-info.component';

@Component({
  selector: 'ea-item-price',
  standalone: true,
  imports: [CommonModule,BnplInfoComponent],
  templateUrl: './item-price.component.html',
  styleUrls: ['./item-price.component.scss'],
})
export class ItemPriceComponent {
  @Input() price?: Price | null;
  @Input() priceRange?: any | null;
  @Input() isChanged : boolean =false;
  showClearPayment =  false;
  installmentCount: number = 4;
  paymentAmount: number = 24.75;
  paymentProvider: string = 'Clearpay';
  showInfo: boolean = false;
  currency = environment.stripe.country == 'GB' ? '£' : '$';


  showClearPay(){
    this.showClearPayment = !this.showClearPayment;
  }
}
