import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { map, Observable } from 'rxjs';
import { CartFacade, CartResponse } from '@ea/cart';
import { Router } from '@angular/router';
import { selectAuthState } from '@ea/auth';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@ea/components';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'ea-subtotal-mobile',
  standalone: true,
  imports: [CommonModule, MatIcon],
  templateUrl: './subtotal-mobile.component.html',
  styleUrl: './subtotal-mobile.component.scss',
})
export class SubtotalMobileComponent extends BaseComponent {
  cart$?: Observable<CartResponse>;
  isAuthenticated$ = new Observable<boolean>();
  total$: any;
  totalToPay: number = 0;
  remove = false;
  @Input() isAuthPage : boolean = false
  constructor(
    private facade: CartFacade,
    private router: Router,
    private store: Store,
  ) {
    super();
    this.cart$ = this.facade.cart$;
    this.isAuthenticated$ = this.store
      .select(selectAuthState)
      .pipe(map((state) => state.isAuthenticated));
    this.total$ = this.cart$.pipe(
      map((cart) => {
        const total = cart.items?.reduce((acc, item) => acc + item.totalPrice.amount, 0) ?? 0;
    this.totalToPay = total; // Safely update totalToPay outside of the reducer
    return total - cart.totalDiscount;
      }),
    );
  }
  removePromo(){
  
    if(this.cart$){
      this.remove = true;
    this.cart$?.subscribe(x => {    
      if(this.remove){
        this.remove = false;
      this.facade.removePromoCode(x.cartId, x.allPromosApplied[0].promoCode)
    }})
    }

  }
  gotoCheckout() {
    this.toggleDisable(true);
    this.isAuthenticated$.subscribe((isAuth) => {
      if (isAuth) {
        this.router.navigate(['/checkout']);
      } else {
        const queryParams = { redirectRoute: `checkout` };
        this.router.navigate(['/cart/auth'], { queryParams });
      }
      this.toggleDisable(false);
    });
  }
}
