import { Attribute } from '@ea/products';

export type Cart = { items: CartItem[] };

export function getDefaultCart(name = ''): CartResponse {
  return {
    cartId: '',
    items: [],
    createdAt: '',
    updatedAt: '',
    totalAmount: 0,
    totalDiscount: 0,
    totalFees: 0,
    totalItems: 0,
    totalUniqueItems: 0,
    cartState: '',
    accountId: '',
    currency: '',
    subTotal: 0,
    metadata: {
      name,
    },
    error: {},
    configuration: {
      allowAnonymousUser: false,
      orderNumberSource: '',
      softReserve: false,
    },
    stematedShipping : 0
  };
}

export type CartRequest = {
  cartId?: string;
  customId?: string;
};

export type UpdateItemRequest = {
  items: CartItemUpdateRequest[];
  name: string;
  cartId: string;
};

export type AddItemsToUserCartRequest = {
  cartId: string;
  customId: string;
};

export type AddItemRequest = {
  items: CartItemAddRequest[];
  name: string;
  cartId: string;
};

export type CartItemAddRequest = {
  itemId: string | number;
  type: string;
  priceListId: number;
  price: PriceRequest;
  quantity: number | null;
  extra: ExtraAttribute;
};

export type ExtraAttribute = {
  parentName: parentAttribute;
  breadcrumbsString: parentAttribute;
  orginalPrice: parentAttribute;
  extra? : Extra;
};
export type Extra ={
  customValues : string
}

export type CustomValue ={
  message : string
}
export type parentAttribute = {
  attributeId: string;
  name: string;
  description: string;
  type: string;
  value: string;
  price: number;
};
export type CartItemUpdateRequest = {
  itemId: string | number;
  priceListId: number;
  lineItemId: number;
  price?: PriceRequest;
  quantity: number | null;
  giftMessage? : string;
  isMessageChange?: boolean;
};

export type PriceRequest = PriceCurrencyRequest & {
  amount: number;
};

export type PriceCurrencyRequest = {
  currency: string;
};

export type ListCartResponse = {
  carts: CartResponse[];
};

export type BillToAddressCheckout = {
  name: Name;
  phone: CheckoutPhone;
  email: string;
  street1: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  customerId: string;
};

export type PaymentDetail = {
  connectorName: string;
  paymentMethod: string;
  paymentToken: string;
  amount: number;
  currency: string;
  billToAddress: BillToAddressCheckout;
};

export type ItemTax = {
  lineItemId: number;
  amount: number;
  type: string;
};

export type ShipToTax = {
  shipToId: string;
  amount: number;
  type: string;
};

export type EstimatedTax = {
  itemsTaxes: Array<ItemTax>;
  shipToTaxes: Array<ShipToTax>;
};

export type FabricCheckoutRequest = {
  cartId: string;
  customerEmail: string;
  charityId : number;
  customerName: Name;
  paymentDetails: Array<PaymentDetail>;
  estimatedTax: EstimatedTax;
  customerPhoneNumber: CheckoutPhone;
  payment_method: string;
  stripId: string;
};

export type WithIntentRequest = {
  exp_month: string;
  exp_year: string;
  number: string;
  cvc: string;
  amount: number;
  currency: string;
  fabric_cartid: string;
  customId: string;
  saveCart: boolean;
  userId: string;
  billingAddress : BillingAddress
};

export type WithIntentRequestClearPay = {
  amount: number;
  currency: string;
  fabric_cartid: string;
  customId: string;
  saveCart: boolean;
  userId: string;
  billingAddress : BillingAddress
};

export type BillingAddress ={
  email : string,
  addressLine1: string;
  addressLin2:string;
  city: string;
  state: string;
  postal_code :number;
  country: string;
  name: string;
  phone: string;
}

export type createIntentRequest = {
  saveCard: boolean;
  customId: string;
  amount: string;
  currency: string;
  payment_method: string;
  fabric_cartid: string;
  billingAddress : BillingAddress;
};
export type IntentResponse = {
  next_action: NextAction;
  client_secret: string;
  payment_method: string;
};

export type CardItem = {
  type: string;
  last4: string;
  paymentMethodId: string;
};

export type NextAction = {
  type: string;
  redirect_to_url: RedirectToUrl;
};

export type RedirectToUrl = {
  return_url: string;
  url: string;
};

export type CartResponse = {
  cartId: string;
  items?: CartItem[];
  createdAt: string;
  updatedAt: string;
  totalItems: number;
  totalUniqueItems: number;
  cartState: string;
  accountId: string;
  currency: string;
  configuration: Configuration;
  totalAmount: number;
  subTotal: number;
  totalDiscount: number;
  metadata: Metadata;
  error: Error;
  totalFees: number;
  allPromosApplied?: any;
  stematedShipping : number
};

export type Configuration = {
  allowAnonymousUser: boolean;
  orderNumberSource: string;
  softReserve: boolean;
};

export type Metadata = {
  name: string;
};

export type Error = {
  error?: string;
};

export type CartItemsByVendor = {
  vendorId: string;
  shipTo?: ShipTo;
  venorName: string;
  items: CartItem[];
};

export type CartItem = {
  cartItemId: string;
  itemId: string;
  title: string;
  sku: string;
  attributeTotalPrice: number;
  createdAt: string;
  updatedAt: string;
  quantity: number;
  type: string;
  backorder: boolean;
  priceListId: string;
  lineItemId: number;
  isActive: boolean;
  unitPrice: UnitPrice;
  totalPrice: TotalPrice;
  attributes: Attribute[];
  isPickup: boolean;
  shipTo: ShipTo;
  channelId: number;
  group: string[];
  extra: ExtraAttribute;
};

export type UnitPrice = {
  currency: string;
  amount: number;
  sale: number;
  discount: Discount;
};

export type Discount = {
  price: number;
  discountAmount: number;
};

export type TotalPrice = {
  currency: string;
  amount: number;
  sale: number;
  discount: Discount;
};

export type ShipMethod = {
  cost: Cost;
  shipMethodId: string;
  shipmentCarrier: string;
  shipmentMethod: string;
};

export type Cost = {
  amount: number;
  currency: string;
  discount: number;
};

export type Name = {
  first: string;
  last: string;
};

export type Address = {
  attention: string;
  street1: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  email: string;
  name: Name;
  phone: Phone;
};

export type ShipTo = {
  shipToId: string;
  createdAt: string;
  updatedAt: string;
  shipMethod: ShipMethod;
  address: any;
  shipToType: string;
  isPickup: boolean;
};

export type Phone = {
  number: string;
  kind: string;
};

export type CheckoutPhone = {
  number: string;
  kind: string;
};
export type CreatePaymentMethod = {
  exp_month: string;
  exp_year: string;
  number: string;
  cvc: string;
  customId: string;
  saveCart: boolean;
  userId: string;
  name: string;
};

export type TaxResponse = {
  subTotal: number;
  total: number;
  totalTax: number;
  percentage: string;
  countyTax: number;
  stateTax: number;
  lineItems: [
    {
      extendedPrice: number;
      totalTax: number;
    }
  ];
};
