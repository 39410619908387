import { createAction, props } from '@ngrx/store';
import {
  AddItemRequest,
  CartItem,
  CartResponse,
  UpdateItemRequest,
} from '../models';
import { ShipMethod } from '../models/cart.model';

export enum CartActionTypes {
  INIT = '[cart] INIT',
  ADD_PRODUCT = '[cart] ADD_PRODUCT',
  UPDATE_ITEM_SHIPPING_METHOD = '[cart] UPDATE_ITEM_SHIPPING_METHOD',
  UPDATE_PRODUCT = '[cart] UPDATE_PRODUCT',
  REMOVE_PRODUCT = '[cart] REMOVE_PRODUCT',
  UPDATE_ITEM_FAILED = '[cart] UPDATE_ITEM_FAILED',
  FIND = '[cart] FIND',
  GET = '[cart] GET',
  SET = '[cart] Set',
}

const init = createAction(CartActionTypes.INIT);
const set = createAction(CartActionTypes.SET, props<CartResponse>());
const find = createAction(CartActionTypes.FIND, props<{ customId: string }>());
const get = createAction(CartActionTypes.GET, props<{ cartId: string }>());

const updateItemFailed = createAction(CartActionTypes.UPDATE_ITEM_FAILED);
const addProduct = createAction(
  CartActionTypes.ADD_PRODUCT,
  props<AddItemRequest>()
);

const updateProduct = createAction(
  CartActionTypes.UPDATE_PRODUCT,
  props<{ cartId: string; request: UpdateItemRequest }>()
);

const updateItemShippingMethod = createAction(
  CartActionTypes.UPDATE_ITEM_SHIPPING_METHOD,
  props<{
    cartId: string;
    address: any;
    shipMethod: ShipMethod;
    items: CartItem[];
  }>()
);

const removeProduct = createAction(
  CartActionTypes.REMOVE_PRODUCT,
  props<{ cartId: string; lineItemId: number }>()
);

export const loginWithCartItems = createAction(
  '[Auth] Login User With Cart Items',
  props<{ cartId: string; customId: string }>()
);

export const applyPromoCode = createAction(
  'Apply Promo Code',
  props<{ cartId: string; promoCode: string }>()
);
export const removePromoCode = createAction(
  'Remove Promo Code',
  props<{ cartId: string; promoCode: string }>()
);
export const cartActions = {
  init,
  addProduct,
  updateProduct,
  updateItemShippingMethod,
  updateItemFailed,
  find,
  get,
  set,
  removeProduct,
  loginWithCartItems,
  applyPromoCode,
  removePromoCode,
};
