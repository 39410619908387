<ng-container *ngIf="cart$ | async as cart">
  <table class="w-100">
    <!-- <caption class="sr-only">
      Totals
    </caption> -->
    <tbody class="leading-relaxed">
      <tr>
        <td class="text-left" scope="row">
          <span>Basket subtotal</span>
        </td>
        <td class="text-right">
          <h2>{{ total$ | async | currency: cart.currency }}</h2>
        </td>
      </tr>
      <tr>
        <td class="text-left py-3" scope="row">
          <span>Estimated delivery
          </span>
        </td>
        <td class="text-right">
          <h2>{{ estimatedShippingAmount| currency: cart.currency }}</h2>
        </td>
      </tr>
      <tr class="savings"  *ngIf=" cart.allPromosApplied">
        <td>  <strong  *ngIf=" cart.allPromosApplied"><span>Promotion applied</span></strong> <br>
        <td class="text-right">  
          <button
            class="remove-btn"
            (click)="removePromo()"
          >
            <mat-icon>close</mat-icon> Remove
          </button> </td>
        </tr>
      <tr   *ngIf=" cart.allPromosApplied">

        <td class="text-left py-3" scope="row">

        
          <span>{{cart.allPromosApplied[0].promoCode}}
          </span>
        </td>
        <td class="text-right">
          
          <h2> - {{ cart.totalDiscount | currency: cart.currency }}</h2>
        </td>
      </tr>

      <tr  class="savings">
        <th class="text-left py-3" scope="row">
          <strong><span>Total to pay</span></strong>
        </th>
        <td class="text-right">
         <strong> <span>{{      this.totalToPay +estimatedShippingAmount - cart.totalDiscount  | currency: cart.currency }}</span></strong> 
        </td>
        
      </tr>
    </tbody>
  </table>
</ng-container>
