<section class="pb-5">
  <div class="container" *ngIf="user$ | async as user">
    <div class="header">
      <h1 >Your Orders</h1>
    </div>
    <div *ngIf="(!orders || orders.length == 0) && !loader">
      <p class="text-theme-on-background">You have no order yet</p>
    </div>
    <div *ngIf="loader" style="padding: 0px; margin-bottom: 0px">
      <ngx-skeleton-loader count="1" [theme]="{
          'height.px': 150,
          'background-color': '#f3f3f3'
        }"></ngx-skeleton-loader>
    </div>
    <div class="col-lg-12">
      <div *ngFor="let order of orders">
        <div
          class=" order-card">
          <div class="row disctop">
            <div class="col-lg-7 row">
              <div class="col-5">
                <p class="text-theme-on-surface">Order placed</p>
                <div class="mt-3"></div>
                <p class="text-theme-on-surface">
                  {{ order.createdAt | date : "d MMMM y" }}
                </p>
              </div>
              <div class="col-3">
                <p class="text-theme-on-surface">Total</p>
                <div class="mt-3"></div>
                <p class="text-theme-on-surface">
                  {{ order.orderTotal | currency : order.currency}}
                </p>
              </div>
              <div class="col-4">
                <p class="text-theme-on-surface">Dispatched to</p>
                <div class="mt-3"></div>
                <p class="text-theme-on-surface">
                  {{ order.shipInfo[0].shipToAddress.name.first}}      {{ order.shipInfo[0].shipToAddress.name.last}}
                </p>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="float-right">
                <span class="text-theme-on-surface"><small>
                  Order: # 
                  <div class="mt-3"></div>
                </small>{{ order.orderNumber
                      }}</span>
                      <div *ngIf="order.items" class="">
                        <a [routerLink]="['order-details/', order.orderNumber]"
                          class="ea-button ea-button-primary-stroked mt-2 mb-2 font-title ">View order details</a>
                      </div>
              </div>
            </div>
          </div>
  
          <div class="mobile">
            <div class="d-flex justify-content-between mt-2">
              <p class="text-theme-on-surface">Order placed</p>
              <p class="text-theme-on-surface">
                {{ order.createdAt | date : "d MMMM y" }}
              </p>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <p class="text-theme-on-surface">Total</p>
                <p class="text-theme-on-surface">
                  {{ order.orderTotal | currency : order.currency}}
                </p>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <p class="text-theme-on-surface">Dispatched to</p>
              <p class="text-theme-on-surface">
                {{ order.shipInfo[0].shipToAddress.name.first}}      {{ order.shipInfo[0].shipToAddress.name.last}}
              </p>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <small>
                Order: # </small> <span>{{ order.orderNumber
                    }}</span>
            </div>
            <div class="row m-2">
              <button  (click)="goToOrderDetailsEvent(order.orderNumber)" 
              class="ea-button ea-button-primary-stroked mt-2 mb-2 font-title ">View order details</button>
            </div>
          </div>

       
          <div *ngFor="let status of order.groupedItems | keyvalue" class="item-div m-100">
            <!-- Loop through vendor names within the status group -->
            <div *ngFor="let vendorGroup of status.value | keyvalue">
              <div class="items-header">
                <!-- Display status and vendor name as the title -->
                <span>{{ status.key }}  {{ vendorGroup.key }}</span>
              </div>
          
              <!-- Loop through items under this vendor group -->
              <div *ngFor="let item of vendorGroup.value">
                <div class="row m-100 items-details disctop">
                  <div class="col-md-2 col-lg-2">
                    <img [src]="item.product_image" alt="Product Image" />
                  </div>
                  <div class="col-md-10 col-lg-10">
                    <h2 class="text-theme-on-surface">{{ item.title }}</h2>
                    <p class="text-theme-on-surface mt-3">
                      Quantity: {{ item.orderedQuantity }}
                    </p>
                  </div>
                  <hr *ngIf="vendorGroup.value[vendorGroup.value.length - 1] != item" class="mt-2">
                </div>
          
                <div class="row m-100 items-details mobile">
                  <div class="col-4">
                    <img [src]="item.product_image" alt="Product Image" />
                  </div>
                  <div class="col-8">
                    <h2 class="text-theme-on-surface">{{ item.title }}</h2>
                    <p class="text-theme-on-surface mt-3">
                      Quantity: {{ item.orderedQuantity }}
                    </p>
                  </div>
                  <hr *ngIf="vendorGroup.value[vendorGroup.value.length - 1] != item" class="mt-2">
                </div>
              </div>
            </div>
          </div>
          
      </div>
      </div>
    </div>
  </div>
</section>