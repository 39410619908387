import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FormFieldComponent } from 'src/app/components/form-field/form-field.component';
import { CartService } from '../../services';
import {
  CartItem,
  CartItemsByVendor,
  CartResponse,
  createIntentRequest,
  FabricCheckoutRequest,
  WithIntentRequest,
  WithIntentRequestClearPay,
} from '../../models/cart.model';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import {
  BehaviorSubject,
  catchError,
  from,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import { BaseComponent } from '@ea/components';
import { IUser } from '@ea/models';
import { CartFacade } from '../../+state';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LocalStorageService } from '@ea/services';
import { MatIconModule } from '@angular/material/icon';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { StripeLoaderService } from '../../services/stripe-loader-service';
import { DataLayerService } from 'src/app/services/shared/WindowReference/DataLayerService';
import environment from '../../../../../environments/environment';
import { CharityData, CharityOrder } from 'src/app/models/CharityData ';
import { CharityService } from 'src/app/services/shared/charityService/charity.service';
import { VertexService } from 'src/app/services/shared/Vertex/Vertex.Service';
import { StripeService } from 'src/app/services/shared/Stripe/stripe.Service';
import { OrderStepsComponent } from '../order-steps/order-steps.component';
import { SelectedAddressComponent } from 'src/app/modules/account/components/selected-address/selected-address.component';
import { CheckoutAddressesComponent } from 'src/app/modules/account/components';
import { CharityChooseComponent } from '../charity-choose/charity-choose.component';
import { InfoCardComponent } from '../info-card/info-card.component';
import { CookieService } from '@ea/auth';

declare let SR: any;

@Component({
  selector: 'ea-payment-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormFieldComponent,
    FormsModule,
    MatIconModule,
    NgxSkeletonLoaderModule,
    OrderStepsComponent,
    CheckoutAddressesComponent,
    CharityChooseComponent,
    InfoCardComponent
  ],
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentFormComponent extends BaseComponent {
  form: FormGroup;
  cards: any;
  selectedFullCard: any;
  thereAreCards: boolean = false;
  isLoaded: boolean = false;
  cart?: CartResponse;
  @Input() user?: IUser;
  @Input() bullingAddress? : string |null;
  @Input() newBullingAddress?: string |null;
  isCardValid: boolean = false;
  ShowCardList: boolean = false;
  displayNewCard: boolean = true;
  error$ = new Subject<string>();
  isLoading$ = new BehaviorSubject<boolean>(false);
  isCartLoaded$ = new BehaviorSubject<boolean>(false);
  isCharityLoaded$ = new BehaviorSubject<boolean>(false);
  orderId?: string;
  orderNumber? :string;
  saveCart: boolean = false;
  selectedCard: string = '';
  private stripe: Stripe | null = null;
  totalTax: number | undefined;
  totalAmount: number | undefined;
  selected: CharityData | undefined;
  options: CharityData[] = [];
  lineItemTax: any | [];
  isLoadingClearPays : boolean = false;
  taxRequest = {
    customer: {
      destination: {
        city: '',
        country: '',
        mainDivision: '',
        postalCode: '',
        streetAddress1: '',
        streetAddress2: '',
      },
    },
    lineItems: [{}],
    transactionId: 'this.cart.cartId',
    documentNumber: '',
  };
  charityText: string = 'With every purchase, EnableAll donates a portion of the proceeds to charity. Please select your preferred charity';

  constructor(
    private fb: FormBuilder,
    private service: CartService,
    private facade: CartFacade,
    private stripeLoaderService: StripeLoaderService,
    private storageService: LocalStorageService,
    private dataLayer: DataLayerService,
    private _change: ChangeDetectorRef,
    private charityService: CharityService,
    private vertexService: VertexService,
    private stripeService: StripeService,
    private readonly cookies: CookieService,
    private cartServices : CartService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
    this.form = this.fb.group({
      firstName: [null, [Validators.required]],
      fullName: [{ value: null, disabled: true }, [Validators.required]],
      lastName: [null, [Validators.required]],
      addressLine1: [null, [Validators.required]],
      addressLine2: [null],
      city: [null, [Validators.required]],
      country: [null, [Validators.required]],
      county: [null, [Validators.required]],
      postalCode: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required]],
      cardNumber: [null, [Validators.required]],
      expiryMonth: [null, [Validators.required]],
      cvc: [null, [Validators.required]],
    });
  }
  itemsByVendor : any;
  estimatedShippingAmount =0;
  async ngOnInit() {
    this.initializeCharityOptions();
    this.initializeStripe();

    this.facade.cart$.subscribe((cart) => {
      this.cart = cart;
      const hash: {
        [key: string]: CartItem[];
      } = {};
      let totral = 0;
      cart.items?.forEach((x) => {
        const key =
          x.attributes.find((a) => a.name === 'vendor_id')?.value ?? '';
        const existing = hash[key] ?? [];
        hash[key] = [...existing, x];
      });
      this.itemsByVendor = Object.keys(hash).map((key) => {
        const value = hash[key];
        const firstItem = value[0];
        const vendorName =
          firstItem.attributes.find((a) => a.name === 'vendor_name')?.value ??
          '';
          this.isLoaded =true;
        return {
          vendorId: key,
          shipTo: firstItem.shipTo,
          venorName: vendorName,
          items: value,
        };
      });

      this.estimatedShippingAmount = this.estimatedShipping(
        this.itemsByVendor,
      );
      this.patchAddress();
      if (this.cart?.items && this.cart.items.length > 0) {
        this.estimatedTax();
      }
      loadStripe(environment.stripe.key).then(stripeInstance => {
        this.stripe = stripeInstance;
        
        const paymentIntentId = this.route.snapshot.queryParamMap.get('payment_intent');
        const clientSecret = this.route.snapshot.queryParamMap.get('payment_intent_client_secret');
        
        if (clientSecret && paymentIntentId) {
          this.isLoading$.next(true);
          this.isLoadingClearPays = true;
          (this.stripe as Stripe).retrievePaymentIntent(clientSecret).then(result => {
            const paymentIntent = result.paymentIntent;
            
            if (paymentIntent?.status === 'succeeded' || paymentIntent?.status === 'requires_capture' ) {
              const request = this.createFabricRequestForClearPay(
                paymentIntentId,
                cart.cartId,
                this.user?.emailAddress!,
                this.storageService.getItem('customId')
              );
      
              this.service.attachPaymentToCart(request).pipe(
                switchMap((cartResult) => {
                 const rEvent = this.prepareEvent();
                  this.orderNumber = cartResult.orderNumber;
                  this.orderId = cartResult.orderId;
                  rEvent.ecommerce.transaction_id = this.orderId;
                  this.sendEvent(rEvent);
      
                  return of(cartResult.checkoutComplete);
                }),
                catchError(() => of(false))
              ).subscribe((checkoutComplete) => {
                if (!checkoutComplete) {
                  this.error$.next('An unknown error occurred while processing your payment.');
                } else {
                  this.postVertexTax(this.taxRequest, this.orderId!);
                  if (this.options.length > 0) {
                    this.attachCharityToOrder(this.calculateAmount());
                  }
                  this.facade.find(this.storageService.getItem('customId'));
      
                  // Custom event tracking
                  SR.event.trackCustomEvent('cart.status', {
                    products: [],
                    totalAmount: 0,
                    totalQuantity: 0,
                    itemIds: [],
                    client: this.cookies.get('_snrs_uuid') || ''
                  }, 'CartStatus');
                  this.router.navigate(['/OrderConfirmation'], { 
                    queryParams: {orderNumber : this.orderNumber,totalAmount: this.totalAmount }
                  });
               //   this.router.navigate(['/OrderConfirmation', parameter1], { queryParams: { key1: value1, key2: value2 } });
                }
                this.isLoadingClearPays  = false;

                this.isLoading$.next(false);
              });
            }
            else{
              setTimeout(() => {
                this.error$.next('Something went wrong during payment');
              this.isLoading$.next(false);
              this.isLoadingClearPays  = false;
              }, 1000); // 1000 milliseconds = 1 second
              
 
            }
          }).catch(error => {
            this.error$.next('Failed to retrieve the payment intent. Please try again.');
            this.isLoadingClearPays  = false;
            this.isLoading$.next(false);
          });
        } 
      });
      
  
    });

    this.stripeService
      .GetUserCards(this.storageService.getItem('customId'))
      .subscribe((cards) => {
        if (cards && cards.length > 0) {
          this.thereAreCards = true;
          this.cards = cards;
          this.selectedCardId(cards[0]);
        }else{
          this.displayNewCard = true;
          this.selectedPaymentMethod = 'newCard' 
        }
        this.isLoaded = true;
        this.isCartLoaded$.next(true);
      });
  }
  disability : string[] =[];
  charities : any;
  selectedDedisability : string ="";
  private initializeCharityOptions() {
    this.charityService
      .getProfileDropDownCheckout(this.storageService.getItem('customId'))
      .subscribe((charities) => {
        if (charities) {
          this.options = charities;
          this.selected =
            charities.find((charity) => charity.isUserDefault) ? charities.find((charity) => charity.isUserDefault) : charities.find((charity) => charity.isDefault) || charities[0];
            this.selectedDedisability = this.selected!.areaofFocusNames;
            this.disability = [...new Set(charities.map(y => y.areaofFocusNames))];
            this.charities = this.options.filter(x => x.areaofFocusNames == this.selectedDedisability)

        }
        this.isCharityLoaded$.next(true);
      });
  }

  estimatedShipping(vendor: CartItemsByVendor[]) {
    return (
      vendor.reduce((acc, item) => {
        return acc + (item.shipTo?.shipMethod.cost.amount ?? 0);
      }, 0) ?? 0
    );
  }
  changeDisability(selectedDedisability : any){
    this.charities = this.options.filter(x => x.areaofFocusNames == selectedDedisability)
  }
  private async initializeStripe() {
    try {
      this.stripe = await this.stripeLoaderService.getStripeInstance();
    } catch (error) {
      console.error('Failed to load stripe:', error);
    }
  }

  estimatedTax() {
    if (!this.cart?.items) {
      return;
    }

    const itemTaxes = this.cart.items.map((item) => ({
      quantity: { value: item.quantity },
      unitPrice: item.unitPrice.amount,
      lineItemId: item.itemId,
      vendorSKU: item.sku,
      inputTotalTax:
        environment.base === 'uk'
          ? (item.unitPrice.amount - item.unitPrice.amount / 1.2).toString()
          : '',
    }));

    this.taxRequest = {
      customer: {
        destination: {
          city: this.form.value['city'],
          country: this.form.value['country'],
          mainDivision: this.form.value['county'],
          postalCode: this.form.value['postalCode'],
          streetAddress1: this.form.value['addressLine1'],
          streetAddress2: this.form.value['addressLine2'] || ' ',
        },
      },
      lineItems: itemTaxes,
      transactionId: this.cart.cartId,
      documentNumber: '',
    };

    this.vertexService.calculateTax(this.taxRequest).subscribe((result) => {
      this.totalTax = result.totalTax;
      this.lineItemTax = result.lineItems;

      if (this.cart && environment.base == 'uk' && this.totalTax === 0) {
         var x = this.cart.items?.filter(x=> x.attributes.filter(y => y.name == "tax_code")[0].value != "uk0");
         var price = 0;
         x!.forEach(element => {
          price+= element.totalPrice.amount;
         });
        this.totalTax =price  - price / 1.2;
        this.totalAmount = this.cart.totalAmount;
      } else {
        this.totalAmount = this.cart!.totalAmount + this.totalTax;
      }

      this._change.markForCheck();
    });
  }

  postVertexTax(request: any, orderId: string) {
    if (this.totalTax !== 0) {
      request.documentNumber = orderId;
      this.vertexService.postTax(request).subscribe(() => {});
    }
  }

  calculateAmount() {
    let amount = 0;
    if (environment.base === 'uk') {
      amount = this.cart!.totalAmount;
    } else {
      amount = this.cart!.totalAmount + (this.totalTax ?? 0);
    }
    return amount;
  }
  prepareEvent() {
    const rEvent: any = {
      event: 'purchase',
      ecommerce: {
        value: this.calculateAmount(),
        currency: this.cart!.currency,
        shipping: 0,
        tax: this.getSumTaxes(),
        affiliation: 'none',
        items: this.getItemForEvent(),
      },
    };
    return rEvent;
  }
  calculateItemTaxes() {
    const itemTaxes = this.cart!.items!.map((x) => {
      let taxAmount = 0;

      if (environment.base === 'uk') {
        taxAmount = 0;
      } else {
        if(this.lineItemTax){
        taxAmount = parseFloat(
          this.lineItemTax.find(
            (y: { lineItemId: string }) => y.lineItemId === 'EALL-' + x.itemId
          )?.totalTax || 0
        );
      }
      else{taxAmount =0;}
      }

      return {
        lineItemId: x.lineItemId,
        amount: taxAmount,
        type: 'vat',
      };
    });
    return itemTaxes;
  }
  getSumTaxes() {
    const tax = this.calculateItemTaxes();
    return tax.reduce((sum, tax) => sum + tax.amount, 0);
  }
  getShippingTaxes() {
    const hash: { [key: string]: CartItem[] } = {};
    this.cart!.items!.forEach((x) => {
      const key = x.shipTo.shipToId;
      const existing = hash[key] ?? [];
      hash[key] = [...existing, x];
    });

    const shipToTaxes = Object.keys(hash).map((key) => ({
      shipToId: key,
      amount: 0,
      type: 'vat',
    }));
    return shipToTaxes;
  }

isClearPay : boolean = false;
clearPayEmail : string = "";
  makePayment() {

    if (
      !this.cart ||
      !this.user ||
      !this.cart.items ||
      !this.cart.items.length ||
      !this.stripe
    ) {
      return;
    }
    const amount = this.calculateAmount();
    const cartId = this.cart.cartId;
    const email = this.user.emailAddress;
    const customerId = this.user.customId;
    this.cartServices.checkInventory(this.cart.items).subscribe(x => {
      if(!x){
    const raw = this.form.getRawValue();
    if(this.isClearPay){
        const request: WithIntentRequestClearPay = {
          amount: amount,
          currency: this.cart!.currency,
          fabric_cartid: this.cart!.cartId,
          customId: this.storageService.getItem('customId'),
          saveCart: this.saveCart,
          userId: this.storageService.getItem('user').userId,
          billingAddress: {
            email: this.clearPayEmail,
            addressLine1: raw.addressLine1,
            city: raw.city,
            state: raw.county,
            country: (raw.country == 'GBP' || raw.country == 'GB' || raw.country.toUpperCase() ==  `UK`) ? 'GB' : 'US',
            postal_code: raw.postalCode,
            addressLin2: raw.addressLine2,
            name: `${raw.firstName} ${raw.lastName}`,
            phone: raw.phoneNumber
  
          }
        };
        this.isLoading$.next(true);
        this.error$.next('');
        const subscription = this.stripeService
          .createPaymentIntentClearPAy(request)
          .pipe(
            catchError(() => {
              this.error$.next(
                'Error creating payment intent. Please check your card information and try again!'
              );
  
              this.isLoading$.next(false);
  
              return of();
            }),
            switchMap((paymentIntent) => {
              if (paymentIntent.client_secret) {
                return from(
                  (this.stripe as Stripe).confirmAfterpayClearpayPayment(
                    paymentIntent.client_secret,
                    {
                      // AfterpayClearpay requires the return_url to complete the payment flow
                      return_url: `${window.location.origin}/uk${this.router.url}`, // Correct return URL
                    }
                  )
                );
              } else {
                this.error$.next(
                  'Error creating payment intent. Please check your card information and try again!'
                );
                this.isLoading$.next(false);
                return of();
              }
            }),
            switchMap((result) => {
              if (result.error) {
                this.error$.next(
                  result.error.message ||
                    'An unknown error occurred while processing your payment.'
                );
                this.isLoading$.next(false);
  
                return of();
              } else {
                if (result.paymentIntent.status === 'requires_capture') {
                  const request = this.createFabricRequest(
                    result,
                    cartId,
                    email,
                    customerId
                  );
                  return this.service.attachPaymentToCart(request).pipe(
                    switchMap((result) => {
                     const rEvent = this.prepareEvent();
                      this.orderNumber = result.orderNumber;
                      this.orderId = result.orderId;
                      this.orderNumber = result.orderNumber;
                      rEvent.ecommerce.transaction_id = this.orderId;
                      this.sendEvent(rEvent);
                      return of(result.checkoutComplete);
                    }),
                    catchError(() => of(false))
                  );
                }
                return of(false);
              }
            })
          )
          .subscribe((result) => {
            if (!result) {
              this.error$.next(
                'An unknown error occurred while processing your payment.'
              );
            } else {
              this.postVertexTax(this.taxRequest, this.orderId!);
              if (this.options.length > 0) {
                this.attachCharityToOrder(amount);
              }
              this.facade.find(customerId);
              SR.event.trackCustomEvent('cart.status', {
                products: [],
                totalAmount: 0,
                totalQuantity: 0,
                itemIds: [],
                client: this.cookies.get('_snrs_uuid') || ''
                }, 'CartStatus');
                this.router.navigate(['/OrderConfirmation'], { 
                  queryParams: {orderNumber : this.orderNumber,totalAmount: this.totalAmount }
                });
            }
            this.isLoading$.next(false);
          });
  
        this.subscriptions.push(subscription);
      
    }
    else if (this.selectedCard !== '') {
      const request: createIntentRequest = {
        payment_method: this.selectedCard,
        amount: amount.toString(),
        currency: this.cart!.currency,
        fabric_cartid: this.cart!.cartId,
        customId: this.storageService.getItem('customId'),
        saveCard: true,
        billingAddress: {
          email: email,
          addressLine1: raw.addressLine1,
          city: raw.city,
          state: raw.county,
          country: (raw.country == 'GBP' || raw.country == 'GB' || raw.country.toUpperCase() ==  `UK`) ? 'GB' : 'US',
          postal_code: raw.postalCode,
          addressLin2: raw.addressLine2,
          name: `${raw.firstName} ${raw.lastName}`,
          phone: raw.phoneNumber
        }
      };

      this.isLoading$.next(true);
      this.error$.next('');
      const subscription = this.stripeService
        .createPaymentIntentOnly(request)
        .pipe(
          catchError((x) => {
            this.error$.next(
              'Error creating payment intent. Please check your card information and try again!'
            );
            this.isLoading$.next(false);
            return of();
          }),
          switchMap((paymentIntent) => {
            if (paymentIntent.client_secret) {
              return from(
                (this.stripe as Stripe).confirmCardPayment(
                  paymentIntent.client_secret
                )
              );
            } else {
              this.error$.next(
                'Error creating payment intent. Please check your card information and try again!'
              );
              this.isLoading$.next(false);
              return of();
            }
          }),
          switchMap((result) => {
            if (result.error) {
              this.error$.next(
                result.error.message ||
                  'An unknown error occurred while processing your payment.'
              );
              this.isLoading$.next(false);
              return of();
            } else {
              if (result.paymentIntent.status === 'requires_capture') {
                const request = this.createFabricRequest(
                  result,
                  cartId,
                  email,
                  customerId
                );
                return this.service.attachPaymentToCart(request).pipe(
                  switchMap((result) => {
                    const rEvent = this.prepareEvent();
                    this.orderNumber = result.orderNumber;
                    this.orderId = result.orderId;
                    rEvent.ecommerce.transaction_id = this.orderId;
                    this.sendEvent(rEvent);
                    return of(result.checkoutComplete);
                  }),
                  catchError(() => of(false))
                );
              }
              return of(false);
            }
          })
        )
        .subscribe((result) => {
          if (!result) {
            this.error$.next(
              'An unknown error occurred while processing your payment.'
            );
          } else {
            this.postVertexTax(this.taxRequest, this.orderId!);
            if (this.options.length > 0) {
              this.attachCharityToOrder(amount);
            }
            this.facade.find(customerId);
            this.router.navigate(['/OrderConfirmation'], { 
              queryParams: {orderNumber : this.orderNumber,totalAmount: this.totalAmount }
            });
          }
          this.isLoading$.next(false);
        });

      this.subscriptions.push(subscription);
    } else {
      const request: WithIntentRequest = {
        cvc: raw.cvc,
        exp_month: raw.expiryMonth.split('/')[0],
        exp_year: raw.expiryMonth.split('/')[1],
        number: raw.cardNumber,
        amount: amount,
        currency: this.cart!.currency,
        fabric_cartid: this.cart!.cartId,
        customId: this.storageService.getItem('customId'),
        saveCart: this.saveCart,
        userId: this.storageService.getItem('user').userId,
        billingAddress: {
          email: email,
          addressLine1: raw.addressLine1,
          city: raw.city,
          state: raw.county,
          country: (raw.country == 'GBP' || raw.country == 'GB' || raw.country.toUpperCase() ==  `UK`) ? 'GB' : 'US',
          postal_code: raw.postalCode,
          addressLin2: raw.addressLine2,
          name: `${raw.firstName} ${raw.lastName}`,
          phone: raw.phoneNumber

        }
      };
      this.isLoading$.next(true);
      this.error$.next('');
      const subscription = this.stripeService
        .createPaymentIntent(request)
        .pipe(
          catchError(() => {
            this.error$.next(
              'Error creating payment intent. Please check your card information and try again!'
            );

            this.isLoading$.next(false);

            return of();
          }),
          switchMap((paymentIntent) => {
            if (paymentIntent.client_secret) {
              return from(
                (this.stripe as Stripe).confirmCardPayment(
                  paymentIntent.client_secret
                )
              );
            } else {
              this.error$.next(
                'Error creating payment intent. Please check your card information and try again!'
              );
              this.isLoading$.next(false);
              return of();
            }
          }),
          switchMap((result) => {
            if (result.error) {
              this.error$.next(
                result.error.message ||
                  'An unknown error occurred while processing your payment.'
              );
              this.isLoading$.next(false);

              return of();
            } else {
              if (result.paymentIntent.status === 'requires_capture') {
                const request = this.createFabricRequest(
                  result,
                  cartId,
                  email,
                  customerId
                );
                return this.service.attachPaymentToCart(request).pipe(
                  switchMap((result) => {
                   const rEvent = this.prepareEvent();
                    this.orderNumber = result.orderNumber;
                    this.orderId = result.orderId;
                    this.orderNumber = result.orderNumber;
                    rEvent.ecommerce.transaction_id = this.orderId;
                    this.sendEvent(rEvent);
                    return of(result.checkoutComplete);
                  }),
                  catchError(() => of(false))
                );
              }
              return of(false);
            }
          })
        )
        .subscribe((result) => {
          if (!result) {
            this.error$.next(
              'An unknown error occurred while processing your payment.'
            );
          } else {
            this.postVertexTax(this.taxRequest, this.orderId!);
            if (this.options.length > 0) {
              this.attachCharityToOrder(amount);
            }
            this.facade.find(customerId);
            SR.event.trackCustomEvent('cart.status', {
              products: [],
              totalAmount: 0,
              totalQuantity: 0,
              itemIds: [],
              client: this.cookies.get('_snrs_uuid') || ''
              }, 'CartStatus');
              this.router.navigate(['/OrderConfirmation'], { 
                queryParams: {orderNumber : this.orderNumber,totalAmount: this.totalAmount }
              });
          }
          this.isLoading$.next(false);
        });

      this.subscriptions.push(subscription);
    }
  } 
  else{
    this.error$.next(x +" has no stock");
  }
  })
  }

  attachCharityToOrder(amount: any) {
    const raw = this.form.getRawValue();
    const request: CharityOrder = {
      charityID: this.selected?.charityId!,
      orderID: this.orderId!,
      personName: raw.firstName + ' ' + raw.lastName,
      totalAmount: amount,
    };
    this.charityService.attachCharityToOrder(request).subscribe();
  }
  public createFabricRequest(
    result: any,
    cartId: string,
    email: string,
    customerId: string
  ) {
    const raw = this.form.getRawValue();
    const itemTaxes = this.calculateItemTaxes();
    const shipToTaxes = this.getShippingTaxes();
    const name = { first: raw.firstName, last: raw.lastName };
    const phone = { number: raw.phoneNumber, kind: 'MOBILE' };

    const request: FabricCheckoutRequest = {
      cartId: cartId,
      customerEmail: email,
      customerName: name,
      charityId:this.selected?.charityId! ,
      payment_method: result.paymentIntent.payment_method!.toString(),
      stripId: customerId,
      paymentDetails: [
        {
          connectorName: 'stripe',
          paymentMethod: 'CARD',
          paymentToken: result.paymentIntent.id,
          amount: result.paymentIntent.amount / 100,
          currency: result.paymentIntent.currency,
          billToAddress: {
            name: name,
            phone: phone,
            email: email,
            street1: raw.addressLine1,
            city: raw.city,
            state: raw.county,
            country: raw.country == 'USA' ? 'USA' : 'GB',
            zipCode: raw.postalCode,
            customerId: customerId,
          },
        },
      ],
      customerPhoneNumber: phone,
      estimatedTax: { itemsTaxes: itemTaxes, shipToTaxes: shipToTaxes },
    };
    return request;
  }


  public createFabricRequestForClearPay(
    result: string,
    cartId: string,
    email: string,
    customerId: string
  ) {
    const raw = this.form.getRawValue();
    const itemTaxes = this.calculateItemTaxes();
    const shipToTaxes = this.getShippingTaxes();
    const name = { first: raw.firstName, last: raw.lastName };
    const phone = { number: raw.phoneNumber, kind: 'MOBILE' };

    const request: FabricCheckoutRequest = {
      cartId: cartId,
      customerEmail: email,
      customerName: name,
      payment_method: "afterpay_clearpay",
      charityId:this.selected?.charityId! ,
      stripId: customerId,
      paymentDetails: [
        {
          connectorName: 'stripe',
          paymentMethod: 'afterpay_clearpay',
          paymentToken:result,
          amount: this.calculateAmount(),
          currency: this.cart!.currency,
          billToAddress: {
            name: name,
            phone: phone,
            email: email,
            street1: raw.addressLine1,
            city: raw.city,
            state: raw.county,
            country: raw.country == 'USA' ? 'USA' : 'GB',
            zipCode: raw.postalCode,
            customerId: customerId,
          },
        },
      ],
      customerPhoneNumber: phone,
      estimatedTax: { itemsTaxes: itemTaxes, shipToTaxes: shipToTaxes },
    };
    return request;
  }

  sendEvent(ev: any) {
    this.dataLayer.SendEvent(ev);
  }

  getItemForEvent() {
    return this.cart!.items!.map((item) => {
      let itemObject = {
        item_name: item.title,
        item_id: item.sku,
        item_price: item.totalPrice.amount,
        item_brand: item.attributes.find((x) => x.name == 'vendor_name')?.value,
        quantity: item.quantity,
        item_variant: item.attributes.find((x) => x.name == 'display_name')
          ?.value,
      };

      const breadcrumbsString = item.extra.breadcrumbsString.value;
      const breadcrumbs = breadcrumbsString?.split('>');
      for (let i = 0; i < breadcrumbs?.length; i++) {
        const cat = breadcrumbs[i];
        const str = 'item.item_category' + (i + 1) + '=' + '"' + cat + '";';
        eval(str);
      }
      return itemObject;
    });
  }
address : any;
  patchAddress() {
    if (this.cart?.items) {
      const address = this.cart.items[0]?.shipTo.address;
      if (address) {
        this.form.patchValue({
          city: address.city,
          country: address.country,
          firstName: address.name.first,
          lastName: address.name.last,
          postalCode: address.zipCode,
          county: address.state,
          addressLine1: address.street1,
          addressLine2: address.street2,
          phoneNumber: address.phone.number,
        });
      }
    }
  }

  changeAddress(address: any){
    if (address) {
      this.form.patchValue({
        city: address.city,
        country: address.country,
        firstName: address.first,
        lastName: address.last,
        postalCode: address.postalCode,
        county: address.county,
        addressLine1: address.addressLine1,
        addressLine2: address.addressLine2,
        addressLine3: address.addressLine3,
        phoneNumber: address.phone.number,
        fullName: `${address.first} ${address.last}`
      });
    }
  }
newCardValue = false;
selectedPaymentMethod : string = "";
  show( isClearPay : boolean = false) {
    if(! isClearPay){
      this.slectdCardoption = "";
      this.displayNewCard = !this.displayNewCard;
      this.selectedCard = '';
      this.form.controls['cardNumber'].setValue(null);
      this.form.controls['expiryMonth'].setValue(null);
      this.form.controls['cvc'].setValue(null);
      this.isClearPay = false;
    }
    if( isClearPay){
      this.slectdCardoption = "";
      this.displayNewCard =false;
      this.isClearPay = true;
      this.selectedCard = '';
    this.form.controls['cardNumber'].setValue(' ');
    this.form.controls['expiryMonth'].setValue(' ');
    this.form.controls['cvc'].setValue(' ');
    }
  }

  showList() {
    this.ShowCardList = !this.ShowCardList;
  }

  onOptionsChanged(event: CharityData) {
    this.selected = event;
  }

  slectdCardoption : any;
  selectedCardId(card: any) {
    this.displayNewCard =false;
    this.isClearPay =false;
    this.selectedPaymentMethod="";
    this.slectdCardoption = card;
    this.selectedCard = card.paymentMethodId;
    this.selectedFullCard = card;
    this.isCardValid = false;
    this.form.controls['cardNumber'].setValue(' ');
    this.form.controls['expiryMonth'].setValue(' ');
    this.form.controls['cvc'].setValue(' ');
    this.showList();
  }

  
}
