import { CommonModule, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SharedModule } from '../../../shared';
import { LocalStorageService, ToastService } from '@ea/services';
import {
  CardItem,
  CreatePaymentMethod,
} from 'src/app/modules/cart/models/cart.model';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormFieldComponent } from '../../../../components/form-field/form-field.component';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject, Subject } from 'rxjs';
import { StripeService } from 'src/app/services/shared/Stripe/stripe.Service';
import { AddCardFormComponent } from '../../components/add-card-form/add-card-form.component';
import { ToastComponent } from '@ea/components';
import { Toast } from '@ea/models';

@Component({
  selector: 'app-my-cards-page',
  standalone: true,
  templateUrl: './my-cards-page.component.html',
  styleUrls: ['./my-cards-page.component.scss'],
  imports: [
    CommonModule,
    RouterLink,
    NgFor,
    NgIf,
    SharedModule,
    NgxSkeletonLoaderModule,
    FormFieldComponent,
    MatIconModule,
    AddCardFormComponent,
    ToastComponent,
  ],
})
export class MyCardsPageComponent implements OnInit {
  form: FormGroup;
  cards?: CardItem[];
  loader: boolean = true;
  error$ = new Subject<string>();
  displayNewCard: boolean = false;
  addCard = false;
  isLoading$ = new BehaviorSubject<boolean>(false);
  toast?: Toast;
  constructor(
    fb: FormBuilder,
    private storage: LocalStorageService,
    private toasterService: ToastService,
    private stripeService: StripeService,
  ) {
    this.form = fb.group({
      cardNumber: [null, [Validators.required]],
      expiryMonth: [null, [Validators.required]],
      expiryYear: [null, [Validators.required]],
      cvc: [null, [Validators.required]],
    });
  }

  ngOnInit() {
    this.getCards();
  }

  getCards() {
    const sub = this.stripeService
      .GetUserCards(this.storage.getItem('customId'))
      .subscribe((x) => {
        this.cards = x;
        // this.cards = [
        //   { type: 'visa', last4: '1234', paymentMethodId: '123412341234124' },
        //   { type: 'mastercard', last4: '3456', paymentMethodId: 'q3412342345' },
        // ];

        this.loader = false;
      });
  }
  getCardImageByType(cardType: string): string {
    return 'assets/images/cardsIcons/' + cardType + '.png';
  }

  deleteCard(paymentMethodId: string) {
    this.stripeService.deleteCart(paymentMethodId).subscribe({
      next: (x) => {
        if (x) {
          const myArray = this.cards?.filter((x) => {
            return x.paymentMethodId !== paymentMethodId;
          });
          this.cards = myArray;
          this.toast = {
            duration: 6000,
            message: 'Your card is deleted successfully',
            type: 'success',
          };
        } else {
          this.toast = {
            duration: 6000,
            message: "Sorry, we couldn't delete your card",
            type: 'error',
          };
        }
      },
      error: () => {
        this.toast = {
          duration: 6000,
          message: "Sorry, we couldn't delete your card",
          type: 'error',
        };
      },
    });
  }
  show() {
    this.displayNewCard = !this.displayNewCard;
    this.form.controls['cardNumber'].setValue(null);
    this.form.controls['expiryMonth'].setValue(null);
    this.form.controls['expiryYear'].setValue(null);
    this.form.controls['cvc'].setValue(null);
  }


  cardSaved() {
    this.addCard = !this.addCard;
  }

  closeToast() {
    this.toast = undefined;
  }
  toaster(event: any) {
    this.toast = event;

    if (this.toast?.type == 'success') {
      this.addCard = false;
      this.getCards();
    }
  }
}
