<section>
  <div class="container">
    <div style="padding: 0px; margin-bottom: 0px" *ngIf="loader; else content">
      <div class="row">
        <div class="col-sm-12 col-md-9 pt-5">
          <ngx-skeleton-loader
            count="1"
            [theme]="{
              'height.px': 200,
              'background-color': '#f3f3f3'
            }"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <ng-template #content>
      <h1 class="d-none d-md-flex">Saved Cards</h1>
      <!--      no cards-->
      <div
        class="card-container container"
        *ngIf="(!cards || cards.length == 0) && !loader && !addCard"
      >
        <div class="">
          <p class="">Saving a payment card is secure and easy</p>
          <ul class="ps-3">
            <li>
              Keeping all your information and data safe is paramount at
              EnableAll
            </li>
            <li>
              Your card details are saved directly with a our regulated payment
              provider, Stripe
            </li>
          </ul>
          <button
            class="ea-button ea-button-primary d-block mx-auto my-4"
            (click)="addCard = true"
          >
            Add your first payment card
          </button>
        </div>

        <div class="row">
          <div class="col-12 col-lg-4 mt-2">
            <div class="tile-info">
              <img alt="card number icon" src="assets/images/cardsIcons/cardNumberIcon.svg" />
              <span>Card number</span>
              <p>The long number across the front of the card</p>
            </div>
          </div>
          <div class="col-12 col-lg-4 mt-2">
            <div class="tile-info">
              <img  alt="card expiry date icon" src="assets/images/cardsIcons/cardNumberIcon.svg" />
              <span>Expiry date</span>
              <p>
                Entered as MM/YY.<br />
                Example: 07/27
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-4 mt-2">
            <div class="tile-info">
              <img alt="card cvc/cvv icon" src="assets/images/cardsIcons/cardNumberIcon.svg" />
              <span>CVC / CVV</span>
              <p>3 digit number found on the back of the card</p>
            </div>
          </div>
        </div>
      </div>
      <!--      card list-->
      <div class="d-flex justify-content-between">
      <button   *ngIf="addCard || (cards && cards.length > 0)"class="ea-button mt-4 mb-4 ml-2" (click)=" cardSaved()">Add payment card <mat-icon>arrow_drop_down</mat-icon>
      </button>

      <div class="toast-div disctop"  >
        <ea-toast
        *ngIf="toast"
        [toast]="toast"
        (dispose) ="closeToast()"
        ></ea-toast>
      </div>
      </div>
      <div *ngIf="addCard" class="mt-2">
        <ea-add-card-form (cardSaved)="toaster($event)"></ea-add-card-form>
        <div class="toast-div mobile mt-4"  >
          <ea-toast
          style="min-width: 300px !important;"
          *ngIf="toast"
          [toast]="toast"
          ></ea-toast>
        </div>
      </div>

      <div *ngIf="cards && cards.length > 0">


        <div class="cards-list">
          <div class="card-item my-2" *ngFor="let card of cards">
            <app-cards-grid
              [cardImage]="getCardImageByType(card.type)"
              [cardTitle]="card.type"
              [last4]="'**** ' + card.last4"
              (cardClosed)="deleteCard(card.paymentMethodId)"
              [paymentMethodId]="card.paymentMethodId"
              (toaster)="toaster($event)"
            ></app-cards-grid>
          </div>
        </div>
      </div>
      <!--      add card-->

      <!--      <div class="row">-->
      <!--        <div class="account pt-5 pb-5">-->
      <!--          <div-->
      <!--            class="col-sm-12 col-md-9 d-flex flex-column justify-content-between mb-3"-->
      <!--          >-->
      <!--            <div class="cards-list row mx-auto rounded p-3 w-100">-->
      <!--              <div class="font-title font-bold pb-3 h1">User Cards</div>-->
      <!--              <span class="layout" (click)="show()"-->
      <!--                ><mat-icon *ngIf="!displayNewCard">play_arrow</mat-icon>-->
      <!--                <mat-icon *ngIf="displayNewCard">arrow_drop_down</mat-icon> New-->
      <!--                card</span-->
      <!--              >-->
      <!--              <div *ngIf="displayNewCard">-->
      <!--                <div class="row">-->
      <!--                  <ea-form-field-->
      <!--                    class="form-field col-sm-12 col-md-6 d-flex justify-content-between align-items-between flex-column"-->
      <!--                    label="Card Number"-->
      <!--                    name="cardNumber"-->
      <!--                    [form]="form"-->
      <!--                  ></ea-form-field>-->
      <!--                  <ea-form-field-->
      <!--                    class="form-field col-sm-12 col-md-6 d-flex justify-content-between align-items-between flex-column"-->
      <!--                    label="Expiry Month"-->
      <!--                    name="expiryMonth"-->
      <!--                    [form]="form"-->
      <!--                  ></ea-form-field>-->
      <!--                  <ea-form-field-->
      <!--                    class="form-field col-sm-12 col-md-6 d-flex justify-content-between align-items-between flex-column"-->
      <!--                    label="Expiry Year"-->
      <!--                    name="expiryYear"-->
      <!--                    [form]="form"-->
      <!--                  ></ea-form-field>-->
      <!--                  <ea-form-field-->
      <!--                    class="form-field col-sm-12 col-md-6 d-flex justify-content-between align-items-between flex-column"-->
      <!--                    label="CVC"-->
      <!--                    name="cvc"-->
      <!--                    [form]="form"-->
      <!--                  ></ea-form-field>-->
      <!--                </div>-->
      <!--                <div class="row">-->
      <!--                  <ng-container *ngIf="error$ | async as error">-->
      <!--                    <div class="error text-red-500 font-bold">{{ error }}</div>-->
      <!--                  </ng-container>-->
      <!--                  <div class="col mt-5 mb-5">-->
      <!--                    <button-->
      <!--                      (click)="saveCard()"-->
      <!--                      title="Save"-->
      <!--                      value="Save"-->
      <!--                      class="ea-button ea-button-primary col-sm-12 col-md-12 col-lg-12 col-xl-12 text-uppercase focus-ring w-100"-->
      <!--                      [disabled]="form.invalid || (this.isLoading$ | async)"-->
      <!--                    >-->
      <!--                      <strong>Save</strong>-->
      <!--                    </button>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <br />-->
      <!--              <br />-->
      <!--              <br />-->
      <!--              <div-->
      <!--                *ngFor="let card of cards"-->
      <!--                class="col-12 col-lg-6 mb-2 payment-card"-->
      <!--              >-->
      <!--                <app-cards-grid-->
      <!--                  [cardImage]="getCardImageByType(card.type)"-->
      <!--                  [cardTitle]="'**** ' + card.last4"-->
      <!--                  [styles]="{-->
      <!--                    height: '47px',-->
      <!--                    backgroundColor: '#f7f7f7',-->
      <!--                    minWidth: 'max-content'-->
      <!--                  }"-->
      <!--                  (cardClosed)="deleteCard(card.paymentMethodId)"-->
      <!--                ></app-cards-grid>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </ng-template>
  </div>
</section>
