import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { BaseComponent } from '@ea/components';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CardItem, CreatePaymentMethod } from '../../../cart/models/cart.model';
import { LocalStorageService, ToastService } from '@ea/services';
import { StripeService } from '../../../../services/shared/Stripe/stripe.Service';
import { BehaviorSubject } from 'rxjs';
import { FormFieldComponent } from '../../../../components/form-field/form-field.component';
import { Toast } from '@ea/models';

@Component({
  selector: 'ea-add-card-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    MatIcon,
    FormFieldComponent,
  ],
  templateUrl: './add-card-form.component.html',
  styleUrl: './add-card-form.component.scss',
})
export class AddCardFormComponent extends BaseComponent {
  form: FormGroup;
  cards?: CardItem[];
  loader: boolean = true;
  isLoading$ = new BehaviorSubject<boolean>(false);
  saveCart: boolean = false;
  @Output() cardSaved = new EventEmitter<Toast>();

  constructor(
    private fb: FormBuilder,
    private storage: LocalStorageService,
    private toasterService: ToastService,
    private stripeService: StripeService,
  ) {
    super();
    this.form = this.fb.group({
      cardNumber: [null, [Validators.required]],
      expiryDate: [null, [Validators.required]],
      // expiryYear: [null, [Validators.required]],
      cvc: [null, [Validators.required]],
      fullName: [null, [Validators.required]],
    });
  }

  saveCard() {
    // return;
    this.isLoading$.next(true);
    const raw = this.form.getRawValue();
    const request: CreatePaymentMethod = {
      cvc: raw.cvc,
      exp_month: raw.expiryDate.split('/')[0],
      exp_year: raw.expiryDate.split('/')[1],
      number: raw.cardNumber,
      customId: this.storage.getItem('customId'),
      saveCart: true,
      userId: this.storage.getItem('user').userId,
      name: raw.fullName
    };
    this.stripeService.createPaymentMethod(request).subscribe(
      (x: any) => {
        if (x.id) {
          this.loader = true;
          const sub = this.stripeService
            .GetUserCards(this.storage.getItem('customId'))
            .subscribe((x) => {
              this.cards = x;
              this.loader = false;
            });
          this.isLoading$.next(false);
          // this.show();
          this.cardSaved.emit({
            duration: 6000,
            message:'Card added successfully',
            type:'success'
          });
        } else {
          this.cardSaved.emit({
            duration: 6000,
            message:'Error creating Card. Please check your card information and try again!',
            type:'error'
          });
        }
      },
      (error: any) => {
        this.isLoading$.next(false);
        this.cardSaved.emit({
          duration: 6000,
          message:'Error creating Card. Please check your card information and try again!',
          type:'error'
        });
      },
    );
  }
}
