<div
  itemprop="offers"
  itemscope
  itemtype="http://schema.org/Offer"
  class="product-price"
  *ngIf="price?.sale"
>
  <h2 itemprop="price" class="text-theme-on-surface" *ngIf="price" >
    <span [ngClass]="{ 'old-price': price.sale }" itemprop ="RegularPrice">
      {{ price!.baseValue | currency : price!.currency }}
    </span>
    <span class="sale-price" *ngIf="price.sale ?? false" itemprop="SalePrice">
      {{ price.sale | currency : price.currency }}
    </span>
  </h2>
</div>
<div
  itemprop="offers"
  itemscope
  itemtype="http://schema.org/Offer"
  class="product-price"
  *ngIf="
 !price?.sale && priceRange && (
    priceRange.min == 0 ||
    priceRange.max == 0 ||
    (priceRange.min == priceRange.max )|| !priceRange
  )
  "
>
  <h2 class="text-theme-on-surface" *ngIf="price ?? true" >
    <span itemprop ="RegularPrice">{{ priceRange?.max ?? 0 | currency : currency }}</span> 

  </h2>
</div>
<div class="product-price">
  <h2
    class="text-theme-on-surface"

    *ngIf="
      priceRange.min != 0 &&
      priceRange.max != 0 &&
      priceRange.min != priceRange.max &&
      !price!.sale
    "
  >
  <span     itemprop="PriceRange">    {{ priceRange.min | currency : currency }}
    -
    {{ priceRange.max | currency : currency }}</span>

  </h2>
</div>
@if(isChanged)
{

    <button class="payment-installment button mt-4"(click)="showClearPay()" >
      @if(price!.sale?? false){
      <span class="installment-text">
        {{ 4 }} interest-free payments of £{{ price!.sale / 4 | number:'1.2-2' }} with 

      </span>}
      @else{
        <span class="installment-text">
        {{ 4 }} interest-free payments of £{{ priceRange.max / 4  | number:'1.2-2'}} with 

      </span>}
        <div class="clearpay-logo-div">
          <img alt="clearpay-logo" class="clearpay-logo" src="assets/images/clearpay-logo-blackmint.png" />
      </div>
    
      <span class="info-icon " (mouseenter)="showInfo = true" (mouseleave)="showInfo = false" >
        <i class="fas fa-info-circle"></i>
      </span>
    </button>
    
  @if(showClearPayment){
    @if(price!.sale && price!.sale <= 1400 ){
      <app-bnpl-info *ngIf="price!.sale" [purchasePrice]="price!.sale/4" [installmentPrice]="price!.sale" class="m-1">
  
      </app-bnpl-info>
    }
    @if(priceRange.max && priceRange.max <= 1400 ){
      <app-bnpl-info [purchasePrice]="priceRange!.max/4" [installmentPrice]="priceRange!.max" class="m-1">
  
      </app-bnpl-info>
    }

  } 
}