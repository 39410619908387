<section>
  <div class="container">
    <!--My Addresses-->
    <div class="my-addresses">
      <h1>Address book</h1>
      <div class="row">
        <div class="col-12 font-title">
          <div class="other-addresses">
            <div>
              <div *ngIf="loader" style="padding: 0px; margin-bottom: 0px">
                <ngx-skeleton-loader
                  count="1"
                  [theme]="{
                    'height.px': 150,
                    'background-color': '#f3f3f3'
                  }"
                ></ngx-skeleton-loader>
              </div>
              <div *ngIf="!loader">
                <ng-container *ngIf="!filteredAddressList.length && !loader">
                  <div class="no-address-section">
                    <h2>Creating an address is easy</h2>
                    <div class="m-3">
                      <ul class="bullet-list" style="font-size: 19px">
                        <li>
                          <span
                            >• Click add address and start typing your address
                            or postcode in address line 1</span
                          >
                        </li>
                        <li>
                          • Address options will appear for you to choose which
                          will automatically fill in all the
                          <br /><span class="ml-2">
                            address fields required</span
                          >
                        </li>
                        <li>
                          • Add an optional nickname to help you choose
                          addresses later
                        </li>
                      </ul>
                      <div class="d-flex justify-content-center">
                        <a
                          class="ea-button ea-button-primary font-title text-uppercase w-50 focus-ring mt-3"
                          [routerLink]="'new-address'"
                          role="button"
                        >
                          Add new address
                        </a>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="d-flex justify-content-between">
                  <button
                    *ngIf="
                      (newdisplayForm ||
                        (filteredAddressList &&
                          filteredAddressList.length > 0)) &&
                      !displayForm && !newdisplayForm
                    "
                    class="ea-button my-4"
                    (click)="newAddress()"
                  >
                    Add new address <mat-icon>arrow_drop_down</mat-icon>
                  </button>
                  <div class="toast-div disctop" *ngIf="toast">
                    <ea-toast
                      *ngIf="toast"
                      [toast]="toast"
                      (dispose)="closeToast()"
                    ></ea-toast>
                  </div>
                </div>
                <div *ngIf="displayForm">
                  <ea-address-editor
                    (addressUpdated)="addressUpdated($event)"
                    [editAddressId]="editAddressId"
                    (dispose)="closeForm()"
                  ></ea-address-editor>
                </div>
                <div *ngIf="newdisplayForm">
                  <ea-address-editor
                    (addressCreated)="addressCreated($event)"
                    (dispose)="closeForm()"
                  ></ea-address-editor>
                </div>
                <div class="toast-div mobile" *ngIf="toast">
                  <ea-toast
                    *ngIf="toast"
                    [toast]="toast"
                    (dispose)="closeToast()"
                  ></ea-toast>
                </div>
                <div *ngFor="let addressResult of filteredAddressList">
                  <ea-address
                    [address]="addressResult"
                    [userCustomId]="userCustomId"
                    (editsAddress)="editsAddress($event)"
                    (deletesAddress)="deleteAddress($event)"
                    (addressUpdated)="addressUpdated($event)"
                    (laoding)="loading()"
                  >
                    <!-- <div class="btn-group">
                    <button
                      (click)="toggleDefault(addressResult, true)"
                      class="ea-button ea-button-primary font-title text-uppercase  focus-ring font-bold"
                      [disabled]="isButtonsDisabled$ | async"
                    >
                      Set as default
                    </button>
                    <a
                      [routerLink]="['edit-address/', addressResult.addressId]"
                      class="ea-button ea-button-stroked font-title text-uppercase w-100 focus-ring font-bold border-style"
                    >
                      Edit
                    </a>
                    <button
                      (click)="deleteAddress(addressResult.addressId)"
                      class="ea-button ea-button-stroked font-title text-uppercase w-100 focus-ring font-bold border-style"
                    >
                      <span class="text-red">Delete</span>
                    </button>
                  </div> -->
                  </ea-address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
