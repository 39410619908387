<div class="d-block d-md-none subtotal-content-mobile col-lg-12">
  <ng-container *ngIf="cart$ | async as cart">
    <ng-container *ngIf="cart.totalItems > 0">
      <div>
        <div class="justify-content-between row" *ngIf=" cart.allPromosApplied">
          <div class="col">
           <span style="font-size:15px ;" *ngIf=" cart.allPromosApplied">Promotion applied</span>
      
          </div>
          <div class="col text-right ">
            <button
            class="remove-btn"
            (click)="removePromo()"
          >
            <mat-icon>close</mat-icon> Remove
          </button>
          </div>
        </div>
        <div class="justify-content-between row" *ngIf=" cart.allPromosApplied">
          <div class="col">
           <span style="font-size:15px ;" *ngIf=" cart.allPromosApplied">{{cart.allPromosApplied[0].promoCode}}</span>
      
          </div>
          <div class="col text-right ">
            <h2> - {{ cart.totalDiscount | currency: cart.currency }}</h2>
          </div>
        </div>

        <div class="justify-content-between row">
          <div class="col">
            <span>Bag( {{ cart.totalItems }} items)</span>
          </div>
          <div class="col text-right total-price">
                      <span>{{
                          total$ | async | currency: cart.currency
                        }}</span>
          </div>
        </div>


        <div class="row ps-3 pe-3 delevery-button">
          <button
            (click)="gotoCheckout()"
            [ngClass]="isAuthPage?   'ea-button ea-button-stroked w-100 p-1 font-title font-bold' :' ea-button ea-button-primary w-100 p-1 font-title font-bold'"
            [disabled]="(isButtonsDisabled$ | async) || isAuthPage"
          >
            Confirm delivery
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
